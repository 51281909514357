import VueRouter from "vue-router";
import vHome from './components/vHome'
import HelloWorld from './components/HelloWorld'
import MyContent from './components/MyContent'
import MyPost from './components/MyPost'
import vCenter from './components/vCenter'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
} //以上三行是为了解决相同路由报错的问题，不用深究
 
export default new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', name: 'vHome', component: vHome},
        {path: '/about', name: 'HelloWorld', component: HelloWorld},
        {path: '/content/:cat/:cat_name', name: 'MyContent', component: MyContent, meta:{title:"文章内容"}},
        {path: '/post/:cat/:parent_cat/:parent_name', name: 'MyPost', component: MyPost, meta:{title:"文章索引"}},
        {path: '/center/:ssid', name: 'vCenter', component: vCenter},
       
    ]
}) 
