<template>
  <v-app id="inspire">
    <v-card>
      <v-navigation-drawer v-model="drawer" app>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> {{ tySysOwner }} </v-list-item-title>
            <v-list-item-subtitle>
              专业 专注
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" link>
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-on:click="jmpPage(item)">
                {{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--  -->
      </v-navigation-drawer>
    </v-card>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ tySysOwner }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!--  -->
      <v-container fluid>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
  
<script>
//import axios from 'axios';

export default {
  data: () => ({
    drawer: null,
    items: [],
    tySysOwner: "睿霁服务",
    logininfo : {}

  }),

  created: async function () {
    
    this.items = [

      { title: "专业主题", icon: "mdi-content", color: "green darken-2", path: "content", cat: "27" },
      { title: "睿霁资讯", icon: "mdi-content", color: "green darken-2", path: "content", cat: "25" },
      { title: "最新进展", icon: "mdi-content", color: "green darken-2", path: "content", cat: "120" },
      { title: "个案分析", icon: "mdi-content", color: "green darken-2", path: "content", cat: "28" }
  
    ];

  },

  methods: {
    jmpPage: function (item) {
      console.log("Jmpitem", item);
      let path = item.path;
      if ( path == "home")
         this.$router.push({ name: "vHome", params: { msg: path } });
      else if ( path == "about")
         this.$router.push({ name: "HelloWorld", params: { msg: path } });
      else if ( path == "content")
         this.$router.push({ name: "MyContent", params: { cat: item.cat , cat_name: item.title } });
      else if ( path == "post")
         this.$router.push({ name: "MyPost", params: { cat: 2520 } });1
    },
  },
};
</script>