<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <div color="#FFFFFF" dark>
          <v-card-title class="text-h5" style="color: #000000">
            {{ post.title }}
          </v-card-title>
          <v-card-actions>
            <v-btn color="orange" style="text-align: right;" text @click="goback()"> 返回上页 </v-btn>
          </v-card-actions>
        </div>
      </v-col>
      <v-col>
        <div style="">

          <div class="mypost" v-html="post.content" ></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    post: {},
    return_path : "/" ,
  }),

  async created() {
    let cat = this.$route.params.cat;
    this.return_path = "/content/"+  this.$route.params.parent_cat + "/" +  this.$route.params.parent_name;
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios
      .get("/single_article?cat=" + cat)
      .then((response) => {
        console.log("catitem  ", response);
        if (response.status == 200) {
          console.log("ok");
          console.log(response.data);
          this.post = response.data;
        } else {
          //window.location = '/login';
        }
      })
      .catch((err) => {
        //window.location = '/login';
        console.log(err);
      });
  },
  methods: {
    goLink: function (link) {
      console.log("The link " + link);
    },
    goback:function(){
      //window.location.href= this.return_path;
      this.$router.go(-1) 
    }
  },
};
</script>

<style>
    img{ max-width: 100%;  height: auto;}
    div.mypost {  line-height: 38px;}
</style>