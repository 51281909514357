<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div color="#FFFFFF" dark>
          <v-card-title class="text-h5" style="color:#000000"> {{ catogery }} </v-card-title>
        </div>
      </v-col>

      <v-col v-for="(item, i) in items" :key="i" cols="12">
        <v-card class="mx-auto" max-width="400">
          <v-img
            class="white--text align-end"
            height="200px"
            :src=" item.src "
          >
            
          </v-img>

          

          <v-card-text class="text--primary">
            <div>{{item.title}}</div>
          </v-card-text>
          <v-card-subtitle style="padding-top: 2px;padding-bottom: 2px "> {{ item.datetime }}  </v-card-subtitle>
          <v-card-actions>
            <v-btn color="orange" style="text-align: right;" text @click="goLink( item.post_id , cat, catogery  )"> 阅读详情 </v-btn>
            
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data: () => ({
    cat : 27,
    catogery : "最新消息",
    items: [
      /*
      {

        src: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
        title: "Supermodel",
        artist: "Foster the People",
        datetime: "2023-04-02",
        link : ''
      },
      {

        src: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
        title: "Halcyon Days",
        artist: "Ellie Goulding",
        datetime: "2023-04-01",
        link : ''
      },*/
    ],
  }),

  async created(){
    let cat = this.$route.params.cat;
    this.catogery = this.$route.params.cat_name;
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/single_cat_json?cat='+cat ).then(
      response=>{
        console.log("catlist  ", response);
        if (response.status==200 ){
            console.log("ok");
            console.log( response.data );
            //this.logininfo = response.data.data;
            let data = response.data[0].list;
            console.log(" data => ", data);
            for ( const item of data){
               let ci = {"src":item.img, "title": item.title,   "post_id": item.id , "datetime": item.datetime};
               this.items.push(ci);
            }
        }else{
          //window.location = '/login';
        }
      }
    )
    .catch(err=>{
      //window.location = '/login';
      console.log(err);
    });
  },
  methods: {
    goLink: function(link, cat, catogery){
      console.log("The link " + link);
      window.location.href="/post/"+link+"/"+cat+"/"+catogery;
    }
  }

};
</script>
