<template>
  <v-container style="margin: 0px; padding: 0px" fluid>
    <v-row justify="space-around" style="margint: -16px; padding: 0px" fluid>
      <v-card width="400">
        <v-img height="200px" src="" style="background: #a88a4e">
          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img alt="user" :src="myimgsrc" />
            </v-avatar>
            <p class="ml-3" @click="doLogin()">{{ nickname }}</p>
          </v-card-title>
          <div style="margin: auto">
            <table
              style="
                text-align: center;
                width: 100%;
                padding-left: 40px;
                padding-right: 40px;
              "
              cellpadding="20px"
            >
              <tr>
                <td> {{ score_upload }}</td>
                <td> {{ score_course }}</td>
                <td> {{ score_jifen }}</td>
              </tr>
              <tr>
                <td>上传数</td>
                <td>课程数</td>
                <td>积分</td>
              </tr>
            </table>
          </div>
        </v-img>
      </v-card>
      <v-card style="width: 100%; margin-top: 8px ;">
        <v-tabs style="width: 100%" background-color="" center-active bright>
          <v-tab @click="doKecheng()">课程</v-tab>
          <v-tab>收藏</v-tab>
          <v-tab>活动</v-tab>
          
        </v-tabs>
      </v-card>
    </v-row>
  </v-container>
</template>


<script>

import axios from 'axios';
export default {

  data: () => ({
    mxid: "",
    nickname: "请点击登陆",
    score_upload : 0,
    score_course : 0,
    score_jifen : 0 ,
    //myimgsrc : '',
    myimgsrc : require('../assets/unkown.jpg') ,
  }),

  async created(){
    let ssid = this.$route.params.ssid;
    console.log("ssid",ssid)
    if (ssid == 'ssid'){
      return ;
    }

    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/subs_api_wx_me' ).then(
        response=>{
          if (response.status==200 ){
              //get normal
              //test if this is ok
              let code = response.data.code ;
              let data = response.data.data;
              if (code == 200){
                this.nickname = data.wx_sns_data['nickname'];
                this.myimgsrc = data.wx_sns_data['headimgurl'];
              }else{
                console.log("go error load wx_me ", code )
                let path = encodeURI('/center');
                window.location.href = '/subs_init_siteauth?domain=wxmm.ruibaogz.com&path='+path;
              }
          }else{
              //need new login
              let path = encodeURI('/center');
              window.location.href = '/subs_init_siteauth?domain=wxmm.ruibaogz.com&path='+path;
          
          }
        }
    ).catch( err=>{ 
        console.log("meet exeception " , err );
    })

  },

  methods:{

    doKecheng: function(){
      console.log("kecheng")
    },
    
    doLogin: function(){
      console.log("I Do Login: ");
      let path = encodeURI('/center');
      window.location.href = '/subs_init_siteauth?domain=wxmm.ruibaogz.com&path='+path;
    }
  }
};
</script>
